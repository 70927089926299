export default {
  namespaced: true,
  state: {
    adjectives: [
      'alizarin',
      'amaranth',
      'amber',
      'amethyst',
      'apricot',
      'aqua',
      'aquamarine',
      'asparagus',
      'auburn',
      'azure',
      'beige',
      'bistre',
      'black',
      'blue',
      'brass',
      'bronze',
      'brown',
      'buff',
      'burgundy',
      'cardinal',
      'carmine',
      'celadon',
      'cerise',
      'cerulean',
      'champagne',
      'charcoal',
      'chartreuse',
      'chestnut',
      'chocolate',
      'cinnabar',
      'cinnamon',
      'cobalt',
      'copper',
      'coral',
      'corn',
      'cornflower',
      'crimson',
      'cyan',
      'dandelion',
      'denim',
      'ecru',
      'emerald',
      'eggplant',
      'firebrick',
      'flax',
      'fuchsia',
      'gamboge',
      'gold',
      'goldenrod',
      'green',
      'grey',
      'harlequin',
      'heliotrope',
      'indigo',
      'ivory',
      'jade',
      'khaki',
      'lavender',
      'lemon',
      'lilac',
      'lime',
      'linen',
      'magenta',
      'magnolia',
      'malachite',
      'maroon',
      'mauve',
      'mustard',
      'myrtle',
      'ochre',
      'olive',
      'olivine',
      'orange',
      'orchid',
      'peach',
      'pear',
      'periwinkle',
      'persimmon',
      'pink',
      'platinum',
      'plum',
      'puce',
      'pumpkin',
      'purple',
      'razzmatazz',
      'red',
      'rose',
      'ruby',
      'russet',
      'rust',
      'saffron',
      'salmon',
      'sangria',
      'sapphire',
      'scarlet',
      'seashell',
      'sepia',
      'silver',
      'tangerine',
      'taupe',
      'teal',
      'thistle',
      'tomato',
      'turquoise',
      'ultramarine',
      'vermilion',
      'violet',
      'viridian',
      'wheat',
      'white',
      'wisteria',
      'yellow',
      'zucchini',
    ],
    nouns: [
      "Aardvark",
      "Albatross",
      "Alligator",
      "Alpaca",
      "Ant",
      "Anteater",
      "Antelope",
      "Ape",
      "Armadillo",
      "Donkey",
      "Baboon",
      "Badger",
      "Barracuda",
      "Bat",
      "Bear",
      "Beaver",
      "Bee",
      "Bison",
      "Boar",
      "Buffalo",
      "Butterfly",
      "Camel",
      "Capybara",
      "Caribou",
      "Cassowary",
      "Cat",
      "Caterpillar",
      "Cattle",
      "Chamois",
      "Cheetah",
      "Chicken",
      "Chimpanzee",
      "Chinchilla",
      "Chough",
      "Clam",
      "Cobra",
      "Cockroach",
      "Cod",
      "Cormorant",
      "Coyote",
      "Crab",
      "Crane",
      "Crocodile",
      "Crow",
      "Curlew",
      "Deer",
      "Dinosaur",
      "Dog",
      "Dogfish",
      "Dolphin",
      "Dotterel",
      "Dove",
      "Dragonfly",
      "Duck",
      "Dugong",
      "Dunlin",
      "Eagle",
      "Echidna",
      "Eel",
      "Eland",
      "Elephant",
      "Elk",
      "Emu",
      "Falcon",
      "Ferret",
      "Finch",
      "Fish",
      "Flamingo",
      "Fly",
      "Fox",
      "Frog",
      "Gaur",
      "Gazelle",
      "Gerbil",
      "Giraffe",
      "Gnat",
      "Gnu",
      "Goat",
      "Goldfinch",
      "Goldfish",
      "Goose",
      "Gorilla",
      "Goshawk",
      "Grasshopper",
      "Grouse",
      "Guanaco",
      "Gull",
      "Hamster",
      "Hare",
      "Hawk",
      "Hedgehog",
      "Heron",
      "Herring",
      "Hippopotamus",
      "Hornet",
      "Horse",
      "Human",
      "Hummingbird",
      "Hyena",
      "Ibex",
      "Ibis",
      "Jackal",
      "Jaguar",
      "Jay",
      "Jellyfish",
      "Kangaroo",
      "Kingfisher",
      "Koala",
      "Kookabura",
      "Kouprey",
      "Kudu",
      "Lapwing",
      "Lark",
      "Lemur",
      "Leopard",
      "Lion",
      "Llama",
      "Lobster",
      "Locust",
      "Loris",
      "Louse",
      "Lyrebird",
      "Magpie",
      "Mallard",
      "Manatee",
      "Mandrill",
      "Mantis",
      "Marten",
      "Meerkat",
      "Mink",
      "Mole",
      "Mongoose",
      "Monkey",
      "Moose",
      "Mosquito",
      "Mouse",
      "Mule",
      "Narwhal",
      "Newt",
      "Nightingale",
      "Octopus",
      "Okapi",
      "Opossum",
      "Oryx",
      "Ostrich",
      "Otter",
      "Owl",
      "Oyster",
      "Panther",
      "Parrot",
      "Partridge",
      "Peafowl",
      "Pelican",
      "Penguin",
      "Pheasant",
      "Pig",
      "Pigeon",
      "Pony",
      "Porcupine",
      "Porpoise",
      "Quail",
      "Quelea",
      "Quetzal",
      "Rabbit",
      "Raccoon",
      "Rail",
      "Ram",
      "Rat",
      "Raven",
      "Reindeer",
      "Rhinoceros",
      "Rook",
      "Salamander",
      "Salmon",
      "Sandpiper",
      "Sardine",
      "Scorpion",
      "Seahorse",
      "Seal",
      "Shark",
      "Sheep",
      "Shrew",
      "Skunk",
      "Snail",
      "Snake",
      "Sparrow",
      "Spider",
      "Spoonbill",
      "Squid",
      "Squirrel",
      "Starling",
      "Stingray",
      "Stork",
      "Swallow",
      "Swan",
      "Tapir",
      "Tarsier",
      "Termite",
      "Tiger",
      "Toad",
      "Trout",
      "Turkey",
      "Turtle",
      "Viper",
      "Vulture",
      "Wallaby",
      "Walrus",
      "Wasp",
      "Weasel",
      "Whale",
      "Wildcat",
      "Wolf",
      "Wolverine",
      "Wombat",
      "Woodcock",
      "Woodpecker",
      "Worm",
      "Wren",
      "Yak",
      "Zebra"
  ]
  }
}
